.nav-tabs .nav-item {
    margin-bottom: -2px;
    height: 31px !important;
    /* background: red; */
}
.nav-tabs .nav-link {
    cursor: pointer;
    outline: none;
    font-weight: bold;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #ffffff;
    background-color: #ee7e22 !important;
    /* border-color: #e2e7f1 #e2e7f1 #fff; */
    border-right: 1px solid #8888 !important;
    height: 31px !important;
}
.tab-content {
    padding:0 !important;
}